import axios from 'axios'

// 查询角色列表
export const GetRole = (params) => axios.get('/api/role', {
  params: params
})
// 查询部门列表
export const GetDept = (params) => axios.get('/api/dept/getDept', {
  params: params
})
// 查询部门下人员
export const GetUserByDeptId = (params) => axios.get('/api/dept/getUserByDeptId', {
  params: params
})
// 添加子部门
export const AddDept = (params) => axios.post('/api/dept/save', {
  ...params
})
// 删除部门
export const DeleteDept = (params) => axios.post('/api/dept/delete', {
  ...params
})
// 添加角色组
export const AddRoles = (params) => axios.post('/api/role/saveGroup', {
  ...params
})
// 添加角色
export const AddRole = (params) => axios.post('/api/role/save', {
  ...params
})
// 修改部门名称
export const DeptUpdate = (params) => axios.post('/api/dept/update', {
  ...params
})
// 修改角色名称
export const RoleUpdate = (params) => axios.post('/api/role/update', {
  ...params
})
// 修改角色组名称
export const RoleUpdateGroup = (params) => axios.post('/api/role/updateGroup', {
  ...params
})
// 删除角色/角色组
export const RoleDelete = (params) => axios.delete('/api/role/delete', {
  params: params
})
// 查询角色下人员
export const GetSelectUser = (params) => axios.get('/api/role/selectUser', {
  params: params
})
// 添加成员
export const SaveUser = (params) => axios.post('/api/role/saveUser', {
  ...params
})
// 添加角色
export const AddUser = (params) => axios.post('/api/dept/addUser', {
  ...params
})
// 更新部门下人员  
export const EditUser = (params) => axios.put('/api/dept/editUser', {
  ...params
})
// 部门列表拖拽
export const DragDept = (params) => axios.put('/api/dept/dragDept', {
  ...params
})
// 角色列表拖拽
export const DragRole = (params) => axios.put('/api/role/dragRole', {
  ...params
})
// 获取子管理员列表
export const SelectChildRole = (params) => axios.get('/api/role/selectChildRole', {
  params: params
})
// 添加系统管理员
export const SaveAdminUser = (params) => axios.post('/api/role/saveAdminUser', {
  ...params
})
// 添加管理组
export const SaveChildRole = (params) => axios.post('/api/role/saveChildRole', {
  ...params
})
// 删除管理组
export const DeleteChildRole = (params) => axios.put('/api/role/deleteChildRole', {
  ...params
})
// 修改管理组
export const UpdateChildRole = (params) => axios.put('/api/role/updateChildRole', {
  ...params
})
// 修改管理组
export const SaveChildRoleUser = (params) => axios.post('/api/role/saveChildRoleUser', {
  ...params
})
// 查询主管理员
export const SelectAdminUser = (params) => axios.get('/api/role/selectAdminUser', {
  params: params
})
// 获取租户信息
export const GetTenantList = (params) => axios.get('/api/tenant/list', {
  params: params
})
// 切换租户
export const ChangeTenant = (params) => axios.put('/api/tenant/changeTenant', {
  ...params
})
// 查询普通管理员信息
export const SelectChildRoleById = (params) => axios.get('/api/role/selectChildRoleById', {
  params: params
})
// 管理员页 获取成员列表
export const SelectChildRolePersonList = (params) => axios.get('/api/role/selectChildRolePersonList', {
  params: params
})
// 管理员页 获取app列表
export const SelectChildRoleAppList = (params) => axios.get('/api/role/selectChildRoleAppList', {
  params: params
})
// 删除角色人员
export const DeleteRoleUser = (params) => axios.put('/api/role/deleteRoleUser', {
  ...params
})

// 删除角色人员
export const EditAccount = (params) => axios.post('/api/reset/password/admin', {
  ...params
})